import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useState } from 'react'
import { useEffect } from 'react'
import { SearchIcon } from '~components/Svg'

const Search = ({ className, initialValue, onUpdate, allArticles }) => {

	const [posts, setPosts] = useState([])
	const [value, setValue] = useState(undefined)
	const [searchTerm, setSearchTerm] = useState(undefined)
	const [counts, setCounts] = useState({
		articles: 0,
	})
	
	const getScore = (article, searchTerm) => {

		let score = 0

		const words = searchTerm.toLowerCase().split(/(?:,| )+/)

		const title = article.title.toLowerCase().split(/(?:,| )+/)
		let titleIntersection = title.filter(x => words.includes(x))

		// You get 5 points for each word that's in the title
		score = score + titleIntersection.length * 5

		if(article.metaDescription){
			const meta = article.metaDescription.toLowerCase().split(/(?:,| )+/)
			let metaIntersection = meta.filter(x => words.includes(x))
			// You get a point for each word that's in the description
			score = score + metaIntersection.length
		}

		if(article?.category?.title){
			const cat = article.category?.title.toLowerCase().split(/(?:,| )+/)
			let categoryIntersection = cat.filter(x => words.includes(x))
			// You get a point for each word that's in the description
			score = score + categoryIntersection.length
		}

		if(article?.productType?.title){
			const cat = article.productType?.title.toLowerCase().split(/(?:,| )+/)
			let typeIntersection = cat.filter(x => words.includes(x))
			// You get a point for each word that's in the description
			score = score + typeIntersection.length
		}

		if(article.excerpt){
			const excerpt = article.excerpt.toLowerCase().split(/(?:,| )+/)
			let excerptIntersection = excerpt.filter(x => words.includes(x))
			// You get a point for each word that's in the description
			score = score + excerptIntersection.length
		}
		return score

	}

	//filter results when searchTerm changes

	useEffect(() => {
		if(searchTerm === ''){
			setPosts(allArticles)
			updateResults()
		} else if(searchTerm && allArticles){
			updateResults()
		} else {
			setPosts(allArticles)
		}
	}, [searchTerm, allArticles])


	const updateResults = () => {
		const articles = []
		allArticles.forEach((article, i) => {
			const score = getScore(article, searchTerm)
			if(score > 0){
				articles.push({...article, score: score})
			}
		})

		setCounts({
			articles: articles.length
		})

		function compare(a, b) {
			if (a.score > b.score) {
				return -1
			}
			if (a.score < b.score) {
				return 1
			}
			return 0
		}

		articles.sort(compare)

		onUpdate({
			articles: articles,
		})
	}

	const submitForm = (event) => {
		event.preventDefault()
		setSearchTerm(value)
	}

	return (
		<Wrap>
			<Form onSubmit={(e) => submitForm(e)}>
				<Input className={className} type='text' onChange={e => setValue(e.target.value)} placeholder={'Search'}/>
				<SearchButton onClick={() => setSearchTerm(value)}>
					<StyledSearchIcon />
				</SearchButton>
			</Form>
		</Wrap>
	)
}
const Wrap = styled.div`
	position: relative;
`
const Form = styled.form`
`
const Input = styled.input`
	background-color: var(--white);
	height: 42px;
	border-radius: 30px;
	width: 100%;
	border: none;
	padding: 10px 20px;
	box-sizing: border-box;
	::placeholder{
		color: var(--extraDarkGrey);
	}
	${mobile}{
		height: 37px;
		padding: 10px 18px;
	}
`
const SearchButton = styled.button`
	color: var(--black);
	position: absolute;
	right: 15px;
	top: 50%;
	${mobile}{
		right: 12px;
	}
`
const StyledSearchIcon = styled(SearchIcon)`
	width: 23px;
	transform: translate(0, -50%);
	${mobile}{
		width: 18px;
	}
`
Search.propTypes = {
	className: PropTypes.string,
	initialValue: PropTypes.string,
	onUpdate: PropTypes.func,
	allArticles: PropTypes.array,
}

export default Search